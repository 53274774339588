import { createRef } from "react"
import { Vector3 } from "three"

const state = {
  sections: 9,
  pages: 8,
  zoom: 75,
  paragraphs: [
    {
      offset: 1,
      factor: 1.75,
      header: "Web3 is the future and it's here to stay",
      image: "/metrics.jpeg",
      aspect: 2.91,
      text: "DEX 24h Trading Volume is above $2B"
    },
    {
      offset: 2,
      factor: 2.0,
      header: "Problem",
      image: "/tool.jpeg",
      aspect: 1.5,
      text: "Explorational solution is in demand"
    },
    {
      offset: 3,
      factor: 2.25,
      header: "The name",
      image: "/explore.jpeg",
      aspect: 1.5037,
      text: ""
    }
    // {
    //   offset: 4,
    //   factor: 2.0,
    //   header: "Disk is FULL",
    //   image: "/keep-calm-the-server-is-down.png",
    //   aspect: 0.965,
    //   text: "Login - each incorrect password throws an error which is logged on main worker - it fills the disk - that's why disk was full and Catastrophe was unavoidable"
    // },
    // {
    //   offset: 5,
    //   factor: 2.0,
    //   header: "Secure your assets",
    //   image: "/security.jpeg",
    //   aspect: 0.965,
    //   text: "Volumes backups - public and not encrypted"
    // },
    // { offset: 7, factor: 1.05, header: "render.com", image: "/render.png", aspect: 1.77, text: "Education and enlightenment lead to better choices!" }
  ],
  stripes: [
    { offset: 0, color: "#000", height: 13 },
    { offset: 6.3, color: "#000", height: 20 }
  ],
  diamonds: [
    { x: 1.5, offset: 0, pos: new Vector3(), scale: 6, factor: 5.2 },
    { x: 2, offset: 1.1, pos: new Vector3(), scale: 1.8, factor: 2.1 },
    { x: -5, offset: 2, pos: new Vector3(), scale: 1.8, factor: 2.5 },
    { x: 0, offset: 3.2, pos: new Vector3(), scale: 1.8, factor: 1.75 },
    { x: 0.3, offset: 4.2, pos: new Vector3(), scale: 1.1, factor: 2.5 },
    { x: 2, offset: 5.5, pos: new Vector3(), scale: 1.25, factor: 0.85 },
    { x: -5, offset: 7, pos: new Vector3(), scale: 1.8, factor: 2 },
    { x: 0, offset: 8, pos: new Vector3(), scale: 2.5, factor: 6 }
  ],
  top: createRef()
}

export default state
